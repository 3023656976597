import { Outlet, Link } from "react-router-dom";
import React, { useState } from "react";
import classNames from "clsx";

export default function Layout() {
  const [isNavClosed, setNavClosed] = useState(true);
  const mobileMenuClassName = classNames("w-full", "md:block", "md:w-auto", {
    hidden: isNavClosed,
  });

  return (
    <>
      <nav className="border-gray-200 px-2 sm:px-4 py-2.5 rounded bg-gray-800">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <a href="/" className="flex items-center">
            <img
              src="/images/logo.png"
              className="mr-3 h-6 sm:h-9"
              alt="Faith Home Restoration Logo"
            />
            <span className="self-center text-xl font-semibold whitespace-nowrap text-white">
              Faith Home Restoration
            </span>
          </a>
          <button
            onClick={() => setNavClosed((prev) => !prev)}
            data-collapse-toggle="mobile-menu"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm rounded-lg md:hidden focus:outline-none focus:ring-2 text-gray-400 hover:bg-gray-700 focus:ring-gray-600"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
            <svg
              className="hidden w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div className={mobileMenuClassName} id="mobile-menu">
            <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
              <li>
                <Link
                  to="/"
                  className="block py-2 pr-4 pl-3 border-b border-gray-100 md:border-0 md:p-0 text-gray-400 hover:text-white hover:underline border-gray-700"
                >
                  Home
                </Link>
              </li>
              <li>
                <a
                  href="https://docs.google.com/document/d/1uw-jh1Z7SSRskY8ICMrtQlWHQonsvww6og8G48Wsbfg/edit?usp=sharing"
                  rel="noreferrer"
                  target="_blank"
                  className="block py-2 pr-4 pl-3 border-b border-gray-100 md:border-0 md:p-0 text-gray-400 hover:text-white hover:underline border-gray-700"
                >
                  Application
                </a>
              </li>
              <li>
                <Link
                  to="/testimonials"
                  className="block py-2 pr-4 pl-3 border-b border-gray-100 md:border-0 md:p-0 text-gray-400 hover:text-white hover:underline border-gray-700"
                >
                  Testimonials
                </Link>
              </li>
              <li>
                <Link
                  to="/donate"
                  className="block py-2 pr-4 pl-3 border-b border-gray-100 md:border-0 md:p-0 text-gray-400 hover:text-white hover:underline border-gray-700"
                >
                  Donate
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="block py-2 pr-4 pl-3 border-b border-gray-100 md:border-0 md:p-0 text-gray-400 hover:text-white hover:underline border-gray-700"
                >
                  About
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="bg-gray-50 min-h-screen">
        <Outlet />
      </div>
      <footer className="bg-gray-800 text-3xl text-white shadow md:flex md:items-center md:justify-between md:p-6 border-gray-200 xl:fixed inset-x-0 bottom-0 p-4">
        <span className="text-sm sm:text-center">
          © 2023 Faith Home Restoration Inc. All Rights Reserved.
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 sm:mt-0">
          <li>
            <a
              href="https://www.facebook.com/faithhomerestoration/"
              className="mr-4 hover:underline hover:text-white md:mr-6 "
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCbOxN5LJ8-Pfw6FQHf5iG_A"
              className="mr-4 hover:underline hover:text-white md:mr-6"
            >
              YouTube
            </a>
          </li>
          <li>
            <a
              href="mailto:board@faithhomerestoration.org"
              className="mr-4 hover:underline hover:text-white md:mr-6"
            >
              Contact
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}
