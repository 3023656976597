import { IKContext, IKImage } from "imagekitio-react";
import React from "react";

export default function About() {
  return (
    <div className="p-2 bg-gray-50 text-gray-800 overflow-y-auto mb-16">
      <div className="max-w-screen-lg mx-auto">
        <h3 className="text-3xl md:text-5xl font-bold mt-0 mb-6">Mission</h3>
        <p className="text-lg md:text-xl font-light leading-relaxed mt-6 mb-4">
          The mission of Faith Home Restoration is to guide women through the
          restoration process with God, sharing our experience, strength, and
          hope to encourage each woman every step of the way. Our aim is to
          provide them with a safe place they can call home.
        </p>
        <h3 className="text-3xl md:text-5xl font-bold mt-8 mb-6">Vision</h3>
        <p className="text-lg md:text-xl font-light leading-relaxed mt-6 mb-4">
          Faith Home Restoration is dedicated to expanding its reach and
          providing support for more women in need. Our goal is to empower those
          within our home to become employable, active members of
          society—individuals who walk in faith and the fullness of God.
        </p>
        <div className="flex flex-col md:flex-row items-start mt-8 mb-6">
          <IKContext urlEndpoint="https://ik.imagekit.io/rtteemwtx">
            <IKImage
              className="w-full md:w-48 h-auto object-cover rounded-lg md:rounded-none md:rounded-l-lg"
              path="/amy.png"
              transformation={[
                {
                  width: "200",
                },
              ]}
            />
          </IKContext>
          <div className="p-6 flex flex-col justify-start">
            <h5 className="text-gray-900 text-lg md:text-xl font-medium mb-2">
              House Manager/Administrator
            </h5>
            <p className="text-gray-700 text-base md:text-lg mb-2">
              Amy Crawford
            </p>
            <p className="text-gray-700 text-base md:text-lg mb-2">
              (512) 552-3259
            </p>
            <a
              className="text-blue-600 underline text-base md:text-lg mb-2"
              href="mailto:amycrawford@faithhomerestoration.org"
            >
              amycrawford@faithhomerestoration.org
            </a>
          </div>
        </div>
        <h3 className="text-3xl md:text-5xl font-bold mt-8 mb-6">History</h3>
        <p className="text-lg md:text-xl font-light leading-relaxed mt-6 mb-4">
          Faith Home was established in August 2013. With over 20 years of
          experience, I have been dedicated to serving women and assisting them
          in gaining a better understanding of their responsibilities in
          society, providing them with the ability to live on their own
          successfully. Faith Home operates as a non-profit corporation, a
          chosen business structure that allows us to accept various donations
          (clothes, food, furniture, money) and provide tax receipts to those
          interested in supporting our mission.
        </p>
        <p className="text-lg md:text-xl font-light leading-relaxed mt-6 mb-4">
          Amy Crawford volunteers as the administrator overseeing all daily
          activities and bills at Faith Home. In addition to Amy, several other
          volunteers contribute by offering prayer, mentoring, transportation,
          computer and resume assistance, lawn maintenance, and addressing the
          women’s personal needs. Our primary objective is to provide a goal
          plan to each woman upon arrival, guiding them toward the ability to
          achieve it.
        </p>
        <p className="text-lg md:text-xl font-light leading-relaxed mt-6 mb-4">
          Our home represents our first leased property, rented from Moxie
          Management, Inc. We depend on the rental income from the residents we
          serve. Additionally, we receive monetary support from Hill Country
          Bible Church, along with charitable donations from private parties.
        </p>
      </div>
    </div>
  );
}
