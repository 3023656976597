import React from "react";

export default function Home() {
  return (
    <div className="min-h-screen flex flex-col justify-center p-2 bg-gray-50 text-gray-800">
      <div className="max-w-screen-lg mx-auto flex-grow">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
          <div className="text-center">
            <img
              className="w-40 mx-auto mb-4"
              alt="Faith Home Restoration Logo"
              src="/images/logo_full.png"
            />
            <p className="text-lg font-light leading-relaxed mb-4">
              The mission of Faith Home Restoration is to guide women through
              the restoration process with God, sharing our experience,
              strength, and hope to encourage each woman every step of the way,
              providing them with a safe place to call home.
            </p>
          </div>
          <div>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube-nocookie.com/embed/nt_dbQ-SF-U"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>
        </div>
        <div className="text-center mt-8">
          <h6 className="font-medium leading-tight text-2xl mb-2 text-gray-600">
            Faith Home
          </h6>
          <p className="text-lg font-light leading-relaxed">
            Faith Home, situated near the NW Austin/Cedar Park area, addresses
            the increasing need for women seeking a fresh start. Our mission is
            to offer a secure and nurturing environment, fostering women&#39;s
            growth in their relationships with God and others.
          </p>
        </div>
      </div>
      <style>
        {`
          @media (max-width: 640px) {
            .grid-cols-1 {
              grid-template-columns: 1fr;
            }
          }
        `}
      </style>
    </div>
  );
}
