import React from "react";

export default function NoPage() {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex-grow flex items-center justify-center">
        <div className="px-4 lg:py-12 max-w-screen-lg mx-auto">
          <div className="lg:gap-4 lg:flex">
            <div className="flex flex-col items-center justify-center md:py-24 lg:py-32">
              <h1 className="font-bold text-blue-600 text-6xl md:text-9xl">
                404
              </h1>
              <p className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
                <span className="text-red-500">Oops!</span> Page not found
              </p>
              <p className="mb-8 text-center text-gray-500 md:text-lg">
                The page you are looking for does not exist.
              </p>
              <a
                href="/"
                className="px-6 py-2 text-sm font-semibold text-blue-800 bg-blue-100"
              >
                Go home
              </a>
            </div>
            <div className="mt-4">
              <img
                src="./images/trash.png"
                alt="img"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-gray-200 text-gray-800 p-2 text-center">
        © 2021 My Website. All rights reserved.
      </footer>
    </div>
  );
}
