import React from "react";
import { IKContext, IKImage } from "imagekitio-react";
import LazyLoad from "react-lazyload";

export default function Testimonials() {
  return (
    <section className="bg-gray-50 text-gray-800">
      <div className="px-4 mx-auto text-center lg:px-6">
        <div className="mx-auto max-w-screen-sm">
          <h2 className="mb-4 mt-4 text-4xl tracking-tight font-extrabold bg-gray-50 text-gray-800">
            Testimonials
          </h2>
          <h3 className="text-lg font-semibold bg-gray-50 text-gray-800">
            Psalm 66:16
          </h3>
          <p className="mb-8 lg:mb-16 text-xl bg-gray-50 text-gray-800">
            &#34;Come and hear, all you who fear God, and I will tell what he
            has done for my soul.&#34;
          </p>
        </div>

        <div className="grid mb-8 lg:mb-12 lg:grid-cols-2">
          <figure className="flex flex-col justify-center items-center p-4 text-center bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r shadow-md rounded-lg">
            <blockquote className="mx-auto mb-8 max-w-2xl text-gray-600 text-xl">
              <p className="my-4 text-left">
                I have been at Faith Home for a year and a half, and my life has
                changed so much for the better. I&#39;ve developed a close
                relationship with the Lord, more profound than I ever imagined.
                Being at Faith Home has allowed me to truly understand what it
                means to have people in my life who love me. The other women
                here are like my sisters, and Amy is amazing—she radiates the
                Lord in everything she does.
              </p>
              <p className="my-4 text-left">
                Faith Home provides numerous resources, including clothes, food,
                financial aid, and much more. I love living here, and I&#39;m
                incredibly grateful for this chapter in my life. I&#39;m happier
                than I&#39;ve ever been because I have a newfound peace since
                learning how much the Lord loves me. I never felt worthy before,
                but I do now. This feels like my home for now, and I love
                helping out in any way I can.
              </p>
              <p className="my-4 text-left">
                I highly recommend Faith Home to anyone interested in coming
                here. I am very, very grateful to Amy and the board for all
                their help. The support here is beyond what I ever thought was
                available. I can&#39;t say enough about this home.
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <IKContext urlEndpoint="https://ik.imagekit.io/rtteemwtx">
                <LazyLoad height={100}>
                  <IKImage
                    className="w-15 rounded-full mx-auto"
                    path="/stacie.png"
                    transformation={[
                      {
                        height: 250,
                        width: 250,
                        crop: "force",
                        quality: 80,
                        breakpoint: 480,
                      },
                      {
                        height: 300,
                        width: 300,
                        crop: "force",
                        quality: 80,
                        breakpoint: 768,
                      },
                      {
                        height: 400,
                        width: 400,
                        crop: "force",
                        quality: 80,
                        breakpoint: 1024,
                      },
                    ]}
                  />
                </LazyLoad>
              </IKContext>
            </figcaption>
          </figure>
          <figure className="flex flex-col justify-center items-center p-4 text-center bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r shadow-md rounded-lg">
            <blockquote className="mx-auto mb-8 max-w-2xl text-gray-600 text-xl">
              <p className="my-4 text-left">
                I was in a terrible place when I came to Faith Home. It was a
                Godsend and truly saved my life. Faith Home has allowed me to
                draw closer to God and become the person He intended me to be.
                I&#39;m incredibly thankful that Faith Home was here to help me
                get my life together. I don&#39;t know what I would have done
                without these women.
              </p>
              <p className="my-4 text-left">
                Amy is an amazing mentor, and she has taught me so many things,
                including how to pray. I believe that God led me here, shaping
                me into the woman He meant me to be. Faith Home has been
                instrumental in my journey of transformation, and I am
                profoundly grateful.
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <IKContext urlEndpoint="https://ik.imagekit.io/rtteemwtx">
                <LazyLoad height={100}>
                  <IKImage
                    className="w-15 rounded-full mx-auto"
                    path="/rachel.png"
                    transformation={[
                      {
                        height: 250,
                        width: 250,
                        crop: "force",
                        quality: 80,
                        breakpoint: 480,
                      },
                      {
                        height: 300,
                        width: 300,
                        crop: "force",
                        quality: 80,
                        breakpoint: 768,
                      },
                      {
                        height: 400,
                        width: 400,
                        crop: "force",
                        quality: 80,
                        breakpoint: 1024,
                      },
                    ]}
                  />
                </LazyLoad>
              </IKContext>
            </figcaption>
          </figure>
          <figure className="flex flex-col justify-center items-center p-4 text-center bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r shadow-md rounded-lg">
            <blockquote className="mx-auto mb-8 max-w-2xl text-gray-600 text-xl">
              <p className="my-4 text-left">
                Over the last 24 years, I have been on a journey filled with
                both ups and downs. It was during the lows in my life that I
                reached out to God. My relationship with God was scattered at
                best, and deep down, I had a thirst to learn more about
                Christianity. However, every time I tried to grow in my
                relationship with Christ, the devil wreaked havoc in my life,
                which in turn scared me away from learning more about my God.
              </p>
              <p className="my-4 text-left">
                I was not equipped with the knowledge or spiritual warfare
                abilities needed to move forward in Christ. Only through the
                power of Jesus and my growing faith in this past year have I
                been able to build my relationship with the most important and
                miraculous being—my God, Jesus Christ, the Faithful Son, and the
                Holy Spirit.
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <IKContext urlEndpoint="https://ik.imagekit.io/rtteemwtx">
                <LazyLoad height={100}>
                  <IKImage
                    className="w-15 rounded-full mx-auto"
                    path="/toni.png"
                    transformation={[
                      {
                        height: 250,
                        width: 250,
                        crop: "force",
                        quality: 80,
                        breakpoint: 480,
                      },
                      {
                        height: 300,
                        width: 300,
                        crop: "force",
                        quality: 80,
                        breakpoint: 768,
                      },
                      {
                        height: 400,
                        width: 400,
                        crop: "force",
                        quality: 80,
                        breakpoint: 1024,
                      },
                    ]}
                  />
                </LazyLoad>
              </IKContext>
            </figcaption>
          </figure>
          <figure className="flex flex-col justify-center items-center p-4 text-center bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r shadow-md rounded-lg">
            <blockquote className="mx-auto mb-8 max-w-2xl text-gray-600 text-xl">
              <p className="my-4 text-left">
                The Lord has profoundly transformed my life. Through Him, I now
                have a home to find stillness, and God has blessed me with a
                good job. I&#39;ve rebuilt relationships with family and
                friends. I am deeply grateful to God and Faith Home for
                providing me with this time and place to grow and become a woman
                of integrity. Most importantly, I am thankful for my
                relationship with Jesus Christ, my Savior. The opportunity and
                blessing that this home is are truly unique!
              </p>
              <p className="my-4 text-left">
                Unfortunately, in my fleshly state, I had placed God on the back
                burner. Today, He is front and center in my life. He was always
                there – He never left. It was me who left and was running away
                from my Lord and Savior. What a blessing it is that He never
                gave up on me, even after I had given up on myself. Faith Home
                has become a place to rest, repent, and reconnect with Jesus.
                With love in my heart.
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <IKContext urlEndpoint="https://ik.imagekit.io/rtteemwtx">
                <LazyLoad height={100}>
                  <IKImage
                    className="w-15 rounded-full mx-auto"
                    path="/kristi.png"
                    transformation={[
                      {
                        height: 250,
                        width: 250,
                        crop: "force",
                        quality: 80,
                        breakpoint: 480,
                      },
                      {
                        height: 300,
                        width: 300,
                        crop: "force",
                        quality: 80,
                        breakpoint: 768,
                      },
                      {
                        height: 400,
                        width: 400,
                        crop: "force",
                        quality: 80,
                        breakpoint: 1024,
                      },
                    ]}
                  />
                </LazyLoad>
              </IKContext>
            </figcaption>
          </figure>
          <figure className="flex flex-col justify-center items-center p-4 text-center bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r shadow-md rounded-lg">
            <blockquote className="mx-auto mb-8 max-w-2xl text-gray-600 text-xl">
              <p className="my-4 text-left">
                I was born and raised in a Christian family here in Austin,
                Texas, with a mom, a dad, and a little brother born just two
                years after me. My childhood took an unexpected and tragic turn
                when my father passed away right as I entered high school at
                only 14 years old. From 14 to 18 years old, I found myself on a
                dark and miserable journey leading nowhere other than death. I
                abused substances, disobeyed my mother, and lost faith in the
                Lord completely.
              </p>
              <p className="my-4 text-left">
                After experiencing so much pain at such a young age and going
                against everything I was taught growing up, I no longer believed
                in God. At 18 years old, I found recovery, and at 19, I gave
                birth to my beautiful daughter, Carter. She brought me back to
                life, healing the shell of a person I was as an adolescent. I
                worked to rebuild my relationship with my mother, something I
                missed and longed for, healing a part of my inner child in pain
                from the loss of my father.
              </p>
              <p className="my-4 text-left">
                But something always felt empty in my heart; there was a longing
                that was always present. Over the last year, I have studied the
                Bible, prayed, and found the Lord again. It hasn&#39;t always
                been an easy road, but it has always been worth it. I have
                complete faith in the Lord regarding all aspects of my life. I
                credit Jesus Christ not only for where I am today as a daughter,
                sister, and mother but simply for me being alive and feeling
                fulfilled. I no longer have that hole in my heart. I feel joy
                and peace in times I once felt empty and lonely.
              </p>
              <p className="my-4 text-left">
                At the age of 22, I submitted myself to the Lord, and He has
                provided beyond what I could have ever hoped or imagined. I have
                infinite love and admiration for my mother, who is my best
                friend, Amy Crawford, Beth Neely, and my amazing daughter.
                Though she is only 3 and a half years old, she has changed me in
                indescribable ways. I outwardly want to express my love for the
                Lord and my faithfulness in His word. My love for Him will
                always serve as a reminder that I was brought out of the pits of
                hell by my faith in Jesus Christ, and I do not have to go back
                there.
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <IKContext urlEndpoint="https://ik.imagekit.io/rtteemwtx">
                <LazyLoad height={100}>
                  <IKImage
                    className="w-15 rounded-full mx-auto"
                    path="/mckenna.png"
                    transformation={[
                      {
                        height: 250,
                        width: 250,
                        crop: "force",
                        quality: 80,
                        breakpoint: 480,
                      },
                      {
                        height: 300,
                        width: 300,
                        crop: "force",
                        quality: 80,
                        breakpoint: 768,
                      },
                      {
                        height: 400,
                        width: 400,
                        crop: "force",
                        quality: 80,
                        breakpoint: 1024,
                      },
                    ]}
                  />
                </LazyLoad>
              </IKContext>
            </figcaption>
          </figure>
          <figure className="flex flex-col justify-center items-center p-4 text-center bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r shadow-md rounded-lg">
            <blockquote className="mx-auto mb-8 max-w-2xl text-gray-600 text-xl">
              <p className="my-4 text-left">
                I came to Faith Home on July 5, 2022. I was in rare form with no
                way out! I packed my bags and hopped on a bus from Fort Worth. I
                came here with absolutely no clue of what was to come. I NEEDED
                JESUS back in my life (being saved already). I was so far away
                from Him. Our God has a way of bringing to our attention
                ultimately - He is all we need!
              </p>
              <p className="my-4 text-left">
                Shortly after my arrival (a month or so), God had a job for me
                that I absolutely love. He is teaching me how to draw closer and
                closer to Him. I&#39;ve stayed sober/clean this whole time only
                because He&#39;s taken the desire away. I&#39;ve found a church
                home (Hill Country Bible Church) and Celebrate Recovery!!! He
                has miraculously and so sweetly opened my eyes to see &amp; ears
                up to listen for and to Him. He has blessed me with a vehicle, a
                home, and a sisterhood of believers to guide me and direct me
                down a narrow path with all the love you could ever imagine!!
                Faith Home and Amy Crawford have given me a whole new lease on
                life! THANK YOU, JESUS.
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <IKContext urlEndpoint="https://ik.imagekit.io/rtteemwtx">
                <LazyLoad height={100}>
                  <IKImage
                    className="w-15 rounded-full mx-auto"
                    path="/amie.png"
                    transformation={[
                      {
                        height: 250,
                        width: 250,
                        crop: "force",
                        quality: 80,
                        breakpoint: 480,
                      },
                      {
                        height: 300,
                        width: 300,
                        crop: "force",
                        quality: 80,
                        breakpoint: 768,
                      },
                      {
                        height: 400,
                        width: 400,
                        crop: "force",
                        quality: 80,
                        breakpoint: 1024,
                      },
                    ]}
                  />
                </LazyLoad>
              </IKContext>
            </figcaption>
          </figure>
        </div>
      </div>
    </section>
  );
}
