import React from "react";

export default function Donate() {
  return (
    <div className="flex flex-col">
      <div className="flex-grow p-2 bg-gray-50 text-gray-800">
        <div className="max-w-screen-lg mx-auto">
          <h1 className="text-3xl md:text-5xl font-bold mt-0 mb-6">
            Sponsor a Resident
          </h1>
          <p className="text-lg md:text-xl font-light leading-relaxed mt-6 mb-4">
            If you are interested in sponsoring a Resident, please take a moment
            to review the details outlined in the document below. The cost to
            support each resident is approximately $750 per month. As residents
            become more self-sufficient, they contribute towards this figure.
            Depending on the resident’s financial circumstances, partial to
            temporary full scholarships may be available. Kindly consider what
            the Lord leads you to contribute to help support these residents on
            their journey back to a Christ-centered life.
          </p>
          <h1 className="text-3xl md:text-5xl font-bold mt-8 mb-6">
            Ongoing Needs
          </h1>
          <ul className="text-lg md:text-xl font-light leading-relaxed mt-6 mb-4 list-disc list-inside">
            <li>
              Cleaning Supplies (trash bags, toilet paper, paper towels, etc.)
            </li>
            <li>Food items (meat, coffee, vegetables, canned goods, etc.)</li>
            <li>Women’s clothing and shoes</li>
            <li>Women’s hygiene items</li>
            <li>HEB or Walmart gift cards for personal needs</li>
          </ul>

          <a
            className="inline-block px-7 py-3 bg-green-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out mt-8"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            href="https://www.aplos.com/aws/give/FaithHomeRestoration"
            role="button"
          >
            Donate Now
          </a>
        </div>
      </div>
    </div>
  );
}
